<template>
      <v-app class>
        <div>
          <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Trainings </h1>
                </div>
                <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
                </div>
              </div>
              <div class="col-md-12 ml-auto mr-auto">
                <v-text-field v-model="searchPersonnel"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details></v-text-field>
              </div>
              <!-- <div :style="{visibility: !trainings.length ? 'visible' : 'hidden'}" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div> -->
              <v-data-table loading-text="Loading data. Please wait...."
                            :loading="isLoading"
                            :headers='headers'
                            :items="trainings"

                             :search="searchPersonnel">
                <template v-slot:item.amount="{ item }">
                  {{item.amount | formatMoney}}
                </template>
                <template v-slot:item.request.dateAdded="{ item }">
                  {{item.request.dateAdded | formatDate}}
                </template>
                <template v-slot:item.request.resumptionDate="{ item }">
                  {{item.request.resumptionDate | formatDate}}
                </template>
                <template v-slot:item.request.startDate="{ item }">
                  {{item.request.startDate | formatDate}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="view(item)" class="mr-2" />
                    <Button v-if="!item.request.approved" :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
                    <!-- <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" /> -->
                  </div>
                </template>

              </v-data-table>
              <!-- View modal  -->
              <Dialog ref="trainingViewModal" :title="'Training'" :width="700">
                <v-row v-if="trainingRequest !== null">
                  <v-col cols="5" style="border-right: 1px solid #d6d6d8">
                    <div class="mb-4">
                      <img class="iconAvatar" src="@/assets/images/avatae.png" alt="">
                    </div>
                    <!-- <h3>First Name</h3> -->
                    <p><span class="text-caption">{{trainingRequest.request.employee.firstName}} {{trainingRequest.request.employee.lastName}}</span></p>

                  </v-col>
                  <v-col cols="7" class="pl-5 pt-5" style="text-align: justify">

                    <p><span class="text-caption">Amount: </span> {{trainingRequest.amount}}</p>
                    <p><span class="text-caption">Added By: </span> {{trainingRequest.request.addedBy}}</p>
                    <p><span class="text-caption">Start Date: </span> {{trainingRequest.request.startDate | formatDate}}</p>
                    <p><span class="text-caption">Resumption Date: </span> {{trainingRequest.request.resumptionDate | formatDate}}</p>
                    <p v-if="trainingRequest.request.status == 0"><span class="text-caption">Status: </span> Pending </p>
                    <p v-if="trainingRequest.request.status == 1"><span class="text-caption">Status: </span> Approved </p>
                  </v-col>
                </v-row>
                   <v-divider></v-divider>

                <v-row>
                  <v-card width="700" elevation="0">
                    <v-tabs v-model="tab">
                    <v-tab href="#tab-1" class="tab-title">Approver</v-tab>
                    <v-tab href="#tab-2">Log</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                        <v-col v-else class="pt-3 pr-1 pl-1" :md="12" v-for="(approver, index) in approvers" :key="index">
                          <p class="text-left mb-1 approval-title">Waiting on ({{approver.approver}}):  {{approver.approvers[0]}}</p>
                     <!--   <v-select
                          :items="approver.approvers"
                          outlined
                          dense
                          :value="approver.approvers[0]"
                        ></v-select> -->
                      </v-col>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <v-card class="pt-2" flat>
                        <div v-if="loadingLogs" class="text-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                        <v-data-table v-else
                                      :headers='logHeaders'
                                      :items="logs">
                          <template v-slot:item.activityDate="{ item }">
                            <span>{{ item.activityDate | formatDate }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  </v-card>
                </v-row>
                <template v-slot:footer>
                  <!-- <Button :btnType="'Close'" :label="'Cancel'" @onClick="closeallowanceViewModal" /> -->
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
         <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment" :mType="'forward'"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>
      </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { FETCH_TRAINING, FORWARD_REQUEST } from '@/store/action-type'
import { requestService } from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
export default {
  components: {
    Button,
    Dialog,
    PersonnelSearch
  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      searchPersonnel: '',
      isLoading: false,
      valid: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      tab: null,
      alertType: '',
      trainingRequest: null,
      personnelSearch: false,
      loadingLogs: false,      
      DelegateName: null,
      DelegateEmail: null,
      comment: '',
      forwarding: false,
      approvers: [],
      logs: [],
      headers: [
        {
          text: 'First Name',
          value: 'request.employee.firstName'
        },
        {
          text: 'Last Name',
          value: 'request.employee.lastName'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Start Date',
          value: 'request.startDate',
          width: '120px'
        },
        {
          text: 'Resumption Date',
          value: 'request.resumptionDate'
        },
        {
          text: 'Added By',
          value: 'request.addedBy',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'request.approvalStatus'
        },
        {
          text: 'Date Added',
          value: 'request.dateAdded',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comment',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }
      ]
    }
  },
  computed: {
    trainings () {
      return this.$store.getters.trainings
    },
    currentUser () {
      // console.clear()
      // console.log(this.$store.getters.ppes)
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    Add () {
      this.$router.push('/training-request/add')
    },
    view (item) {
      this.loadingLogs = true
      requestService.trainingApprovalLogs(item.requestId).then(({ data }) => {
        this.approvers = data.currenApprover
        this.logs = data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
      this.trainingRequest = item

      this.$refs.trainingViewModal.openModal()
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    getUserRole (role) {
      if (this.currentUser.roles.includes(role)) {
        return true
      } else {
        return false
      }
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name     
    },
    getComment (comment) {
      this.comment = comment
    },
    getTrainingData () {
      this.isLoading = true
      this.$store
        .dispatch(FETCH_TRAINING)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          if (error) {
            this.isLoading = false
            // this.showAlertMessage('Unable to display departments', 'error')
          }
        })
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        itemId: this.item.request.employeeId,
        // "ItemId":428,//This should be the Employee Id        
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail,
        'Comment': this.comment
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'Training' }).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })
    }
  },
  mounted () {
    this.getTrainingData()
    // this.$store
    //   .dispatch(FETCH_TRAINING)
    //   .then(() => {})
    //   .catch((error) => {
    //     if (error) {
    //       this.showAlertMessage('Unable to display departments', 'error')
    //     }
    //   })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
.approval-title{
      font-size: 14px;
    font-weight: bold;
    margin-bottom: 40px;
}
.v-tab--active{
   background-color: #faf8f8 !important;
   color: #0b2d71 !important
}
.v-tabs-slider-wrapper{
  background-color: #0b2d71 !important
}
</style>
